import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout title="Errore 404">
    <section className="hero is-warning is-large is-bold">
      <div className="hero-body">
        <h1 className="title is-2">ERRORE 404</h1>

        <h2 className="subtitle">Pagina non trovata</h2>
      </div>
    </section>
  </Layout>
);

NotFoundPage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

export default NotFoundPage;
